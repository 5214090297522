import axios from "axios"

const getAdvertisement = async () => {
    const { data } = await axios.get(
        `${process.env.NEXT_PUBLIC_API_URL}advertisement-groups`
    )

    if (data) {

        return data
    }
    throw new Error("Failed to fetch advertisements")
}

export default getAdvertisement
