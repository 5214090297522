import React, { useState, useMemo, useEffect } from "react"
import { ISections } from "types/ISections"
import getSections from "api/sections/getSections"
import { useQuery } from "@tanstack/react-query"
import NewEventCard from "../../NewEventCard"
import Section from "components/Sections/Section"
import { useLang } from "hooks/useLang"
import Skeleton from "components/Skeleton/Skeleton"
import { Fragment } from "react"
import { sortEventsByStart } from "helpers/events/sortEvents"
import useWindowSize from "hooks/useWindowSize"
import FilterComponent from "components/atoms/Filter/FilterComponent"
import { IFinalEvent } from "types/event"
import { filterEventsByDate, FilterOption } from "helpers/filterEventsByDate"
import FilterComponentSkeleton from "components/Skeleton/FilterComponentSkeleton"

interface IProps {
    maxEventsToShow?: number
}

const EventsSections: React.FC<IProps> = ({ maxEventsToShow }) => {
    const { multiLangVars } = useLang()
    const { isMobile } = useWindowSize()

    const { data, isLoading } = useQuery({
        queryKey: ["sections"],
        queryFn: getSections,
    })

    const sectionsData: ISections[] = data?.payload.sections

    const [filteredEvents, setFilteredEvents] = useState<IFinalEvent[]>([])

    const allEvents = useMemo(() => {
        return sectionsData?.flatMap((section) => section.events) || []
    }, [sectionsData])

    const filterOptions = [
        { label: "All", value: "all" },
        { label: "Today", value: "today" },
        { label: "This week", value: "week" },
        { label: "This month", value: "month" },
    ]

    const handleFilterChange = (filteredData: IFinalEvent[]) => {
        setFilteredEvents(filteredData)
    }

    // Sort events by tickets availability
    const sortEvents = (events: IFinalEvent[]) => {
        return events.sort((a, b) => {
            console.log(a.availableTickets)
            // if (a.availableTickets.length < b.availableTickets.length) return 1
            if (a.availableTickets.length > 0) return -1
            return 0
        })
    }

    useEffect(() => {
        if (allEvents.length > 0) {
            setFilteredEvents(allEvents)
        }
    }, [allEvents])

    if (isLoading) {
        return (
            <>
                {/* <div className="mt-24 layout">
                    <FilterComponentSkeleton />
                </div> */}
                <Section className="layout">
                    <Skeleton
                        className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
                        title
                        type="event_card"
                    />
                    <Skeleton
                        className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
                        title
                        type="event_card"
                    />
                </Section>
            </>
        )
    }

    const renderEventCards = (events: IFinalEvent[]) => {
        return events
            .map((event) => (
                <div
                    key={event?._id}
                    className={
                        isMobile
                            ? "keen-slider__slide py-1 shadow-sm dark:shadow-none"
                            : ""
                    }
                >
                    <NewEventCard event={event} />
                </div>
            ))
            .slice(0, maxEventsToShow ?? events.length)
    }

    return (
        <div>
            {/* <div className="mt-24 layout">
                <FilterComponent
                    location=" Your Location"
                    options={filterOptions}
                    data={allEvents}
                    filterFunction={(event: IFinalEvent, filter: string) =>
                        filterEventsByDate(event, filter as FilterOption)
                    }
                    onFilterChange={handleFilterChange}
                />
            </div> */}

            {sectionsData
                ?.sort((a, b) => a.order - b.order)
                ?.map((section) => {
                    const sectionEvents = filteredEvents.filter((event) =>
                        section.events.some(
                            (sectionEvent) => sectionEvent._id === event._id
                        )
                    )

                    const sliderView = { sm: 1.1, md: 1.1, lg: 1.1, xl: 1.1 }

                    if (sectionEvents.length === 0) {
                        return null
                    }

                    return (
                        <Fragment key={section._id}>
                            <Section
                                className="md:layout"
                                sectionHeaderClassName={
                                    isMobile ? "layout" : ""
                                }
                                type={isMobile ? "slider" : "default"}
                                sliderView={isMobile ? sliderView : undefined}
                                sliderOrigin="center"
                                showSeeMoreButton={
                                    maxEventsToShow !== undefined &&
                                    sectionEvents.length > maxEventsToShow
                                }
                                id={section._id}
                                slug={section.en_title.toLowerCase()}
                                title={
                                    section?.[
                                        multiLangVars("title") as
                                            | "en_title"
                                            | "ar_title"
                                    ]
                                }
                                description={
                                    !isMobile
                                        ? section?.[
                                              multiLangVars("description") as
                                                  | "en_description"
                                                  | "ar_description"
                                          ]
                                        : undefined
                                }
                            >
                                {!isMobile ? (
                                    <div className="div-not-mobile grid grid-cols-1 gap-5 sm:grid-cols-2 md:mx-0 1.5lg:grid-cols-3 1.5xl:grid-cols-4">
                                        {renderEventCards(
                                            sortEventsByStart(sectionEvents)
                                        )}
                                    </div>
                                ) : (
                                    <>
                                        {renderEventCards(
                                            sortEventsByStart(sectionEvents)
                                        )}
                                    </>
                                )}
                            </Section>
                        </Fragment>
                    )
                })}
        </div>
    )
}

export default EventsSections
