import "keen-slider/keen-slider.min.css"

import { useQuery } from "@tanstack/react-query"
import AppBanner from "components/Sections/AppsSection/AppBanner"
import HeroVTwo from "components/HeroVTwo"
import { MainLayout } from "components/Layout"
import AdSection from "components/Sections/AdSection/AdSection"
import CategorySection from "components/Sections/CategorySection/CategorySection"
import CitySection from "components/Sections/CitySection/CitySection"
import DiscoverSection from "components/Sections/DiscoverSection/DiscoverSection"
import EventsSections from "components/Sections/EventsSections/EventsSections"
import { AdvertisementResponse } from "types/Advertisements"
import getAdvertisement from "api/sections/getAdvertisement"
import { NextSeo } from "next-seo"
import { ArSEO } from "../seo/index"

import FeatureFlaggedComponent from "components/FeatureFlaggedComponent"
import Survey from "components/Survey/SurveyV1"
import { useEffect } from "react"
export interface IPromo {
    eventTitle: string
    status: boolean
    id: string
}

export async function getServerSideProps() {
    // const data = await getAdvertisement()
    const res = await fetch(
        `${process.env.NEXT_PUBLIC_API_URL}advertisement-groups`
    )
    const data = await res.json()
    const ads = data.payload

    return {
        props: { ads },
    }
}

const NewHomePage = (props) => {
    const { data, isLoading: isAdvertisementLoading } =
        useQuery<AdvertisementResponse>({
            queryKey: ["advertisement"],
            queryFn: () => getAdvertisement(),
            initialData: props.payload,
        })
    const advertisement = data?.payload

    const advertisementSliders = advertisement?.filter(
        (ad) => ad.web_view_type === "slider"
    )
    const advertisementGrid = advertisement?.filter(
        (ad) => ad.web_view_type === "grid"
    )

    useEffect(() => {
        async function getData() {
            const data = await fetch(
                `${process.env.NEXT_PUBLIC_API_URL}advertisement-groups`
            )
            const ads = await data.json()
            console.log(ads, "afs")
        }
        getData()
    }, [])
    return (
        <>
            <NextSeo {...ArSEO} />

            {/* <Survey /> */}

            <MainLayout>
                <div className="flex flex-col">
                    <HeroVTwo
                        advertisements={advertisementSliders!}
                        isLoading={isAdvertisementLoading}
                    />
                    <DiscoverSection isLoading={isAdvertisementLoading} />

                    <div>
                        <EventsSections maxEventsToShow={12} />

                        {/* <FeatureFlaggedComponent flag="showcategories"> */}
                        {/* <CategorySection /> */}
                        {/* </FeatureFlaggedComponent> */}

                        {/* <SingleAdSection /> */}

                        {advertisement?.length === 0 ? null : (
                            <AdSection
                                advertisements={advertisementGrid!}
                                isLoading={isAdvertisementLoading}
                            />
                        )}
                        <CitySection />
                    </div>

                    <AppBanner />

                    {/* <SearchByKeyword /> */}
                </div>
            </MainLayout>
        </>
    )

}

export default NewHomePage
