import { IFinalEvent } from "types/event"

// TODO: temporary solution for sorting events by start date. refactor this later.
export const sortEventsByStart = (events: IFinalEvent[]) => {
    const specialEvents = ["guvali", "guvali beach", "guvali nights"]
    const filterSpecialEvents =
        events?.filter((event) => {
            return specialEvents?.includes(event?.en_title.toLowerCase())
        }) || []

    const sortEvents =
        events?.sort((a, b) => {
            if (a.createdAt > b.createdAt) {

                return -1
            }
            if (a.createdAt < b.createdAt) {
                return 1
            }

            return 0
        }) || []

    const e = [...filterSpecialEvents, ...sortEvents]


    const filterDuplicateEvents = e?.filter((event, index) => {
        const firstIndex = e?.findIndex((e) => e._id === event._id)
        return firstIndex === index
    })
    return filterDuplicateEvents
}
